import {
  DefaultValuesDetailsForm,
  DetailsFormValues,
  IndividualFormProps,
} from "../../components/Layouts/WebCheckInForms/WebCheckInForms.props";
import React, { useEffect, useRef, useState } from "react";

import AppButton from "../../components/UI/AppButtons/AppButton";
import { AppInputSwitcher } from "../../components/Layouts/AppInputSwitcher/AppInputSwitcher";
import { AppRadioButton } from "../../components/UI/AppRadioButtons/AppRadioButtons";
import { AppSecondaryNavigation } from "../../@types/AppSecondaryNavigation";
import AppStandardButton from "../../components/UI/AppButtons";
import { AppStandardMultiSelectCheckBox } from "../../components/UI/AppMultipleSelection/";
import { BOOKING_ACTIONS } from "../../store/onlineBooking";
import BookingGetters from "../../store/onlineBooking/getters";
import { BreadCrumbs } from "../../components/UI/AppBreadCrumbs";
import { CustomerDetailFormField } from "./UserDetailsForm";
import { KeyboardArrowRight } from "@mui/icons-material";
import { Modal } from "../../components/UI/AppModal";
import { ReservationDetailsPreview } from "../../components/Layouts/ReservationDetailsPreview/ReservationDetailsPreview";
import SecondaryNavConfig from "../../API/responses/SecondaryNavConfig.json";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// import { useWebCheckInForm } from "../../hooks/WebCheckInForm_2";
import { AllPaymentMethodsResponse } from "../../@types/DTO/AllPaymentMethods";
import { PayPal } from "../../components/Layouts/PaymentForms/PayPal";
import { createSearchParams, useNavigate } from "react-router-dom";
import BookingApi from "../../API/Apis/BookingApi";
import { Vehicle } from "../../@types/DTO/Vehicles";
import { SelectedVehicleDetails } from "../../@types/Booking/SelectedVehicleDetails";
//import { Nexi } from "../../components/Layouts/PaymentForms/Nexi";
import { t } from "i18next";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
// import { CompanyInvoice } from "../../components/Layouts/PaymentForms/CompanyInvoice";
import {
  CompanyInvoiceFormFields,
  ReadOnlyIfInitialValuesNotProvided,
  WebCheckInFormFields,
} from "../../components/Layouts/WebCheckInForms/FormOptions";
import { RegisterOptions } from "react-hook-form";
import { FormFieldErrorMessage } from "../../components/UI/FormFieldErrorMessage/FormFieldErrorMessage";
import { SelfDriverFormFields } from "../../components/Layouts/WebCheckInForms/FormKeys";
import { AppInputSwitchProps } from "../../components/Layouts/AppInputSwitcher/AppInputSwitcher.props";
import { object } from "prop-types";
import { BookingDetails } from "../../@types/Booking/bookingDetails";
import { companyDetails } from "../../@types/Booking/companyDetails";
import { LoadingButton } from "@mui/lab";
import {
  ReservationSuccessfullResponse,
  SsnByItalianCustomerDetails,
} from "../../@types/DTO/ApiResponses";
import TagManager from "react-gtm-module";
import { BookingStateRedux } from "../../store/onlineBooking/states";
//import { GTM } from "../../config/googleTagManager";
import { BookingConfirmPage } from "../bookingConfirmation";
import { SSNFormPay } from "../../hooks/SSNFormPay";
//GTM;
function PaymentDetailsPage({
  values = {},
  initialValues = { ...DefaultValuesDetailsForm },
  onSubmit = () => {},
  onChange = () => {},
  children = <></>,
}: IndividualFormProps): JSX.Element {
  const [
    {
      setValue,
      getValues,

      register,
      handleSubmit,
      formState: { errors },
    },
    individualFormFields,
  ] = SSNFormPay(
    CompanyInvoiceFormFields,
    {
      ...Object.keys(values).reduce((prevValue, currentKey) => {
        prevValue[currentKey] =
          prevValue[currentKey] || initialValues[currentKey];
        return prevValue;
      }, values),
    },
    onChange
  );
  const { t: translate } = useTranslation();
  const [companyinvoiceDetails, setCompanyinvoiceDetails] = useState<any>([]);
  const currentLoggedInuser: string | undefined =
    localStorage.getItem("userID") || undefined;
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const payPalFormRef = useRef<HTMLFormElement>();
  const inputTAXRef = useRef<HTMLInputElement>(null);
  const inputVATRef = useRef<HTMLInputElement>(null);
  const AppSettings = BookingGetters.getAppSettings();
  const [checked, setChecked] = React.useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const nexiFormRef = useRef<HTMLFormElement>();
  const [allPaymentMethods, setAllPaymentMethods] = useState<
    Partial<AllPaymentMethodsResponse>
  >({});
  const ReservationDetails = BookingGetters.getBookingDetails();

  const [ReservationId, setReservationId] = useState<number>(0);
  const [taxVal, setTaxVal] = useState("9999999999999999");
  const [isReadonly, setIsReadonly] = useState(true);

  const VehicleDetails = BookingGetters.getSelectedVehicleDetails();
  const UserDetails = BookingGetters.getUserDetails();
  //const [showDisclaimer, setShowDisclaimer] = useState(true);
  const [showDisclaimer, setShowDisclaimer] = useState<boolean>(true);
  useEffect(() => {
    BookingApi.getPrivacyAndTerms().then((e) => {
      setShowDisclaimer(true);
      dispatch(BOOKING_ACTIONS.setRentalTerms(e.data.conditionText));
    });
  }, []);

  // const { t: translate } = useTranslation();
  const [userDetails, setUserDetails] = useState<DetailsFormValues>({
    ...DefaultValuesDetailsForm,
  });

  const [whetherIfGroupIsOnRequest, setWhetherIfGroupIsOnRequest] =
    useState<string>("false");
  useEffect(() => {
    if (VehicleDetails.vehicleDetails?.Status.toLowerCase() == "onrequest") {
      setWhetherIfGroupIsOnRequest("true");
    } else setWhetherIfGroupIsOnRequest("false");
  });

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>("");
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(BOOKING_ACTIONS.setUserDetails(userDetails));
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [userDetails]);

  const [formStates, formFields] = SSNFormPay(
    CustomerDetailFormField,
    userDetails,
    (e) => {
      setUserDetails({ ...userDetails, ...e });
    }
  );

  const [userType, setUserType] = useState("Individual");

  function requestParentResize(checked) {
    let iframeHeight;
    if (checked) {
      if (userType === "Company") {
        iframeHeight = 2566;
      } else {
        iframeHeight = 2854;
      }
    } else {
      iframeHeight = 2198;
    }
    window.parent.postMessage(
      {
        event_id: "iframeHeight",
        data: {
          iframeHeight: iframeHeight,
        },
      },
      "*"
    );
  }
  useEffect(() => {
    if (AppSettings?.showInIframe) {
      requestParentResize(checked);
    }
  }, [checked, userType]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!VehicleDetails.paymentDetails?.price) {
        navigateTo("/");
      }
    }, 1000);
    BookingApi.getAllPaymentMethods().then((e) => {
      setAllPaymentMethods(e);
    });
    return () => {
      clearTimeout(timer);
    };
  }, []);
  const searchParams: string = createSearchParams({
    dropOffDateAndTime: new Date(ReservationDetails.dropOffDateAndTime)
      .getTime()
      .toString(),
    pickUpDateAndTime: new Date(ReservationDetails.pickUpDateAndTime)
      .getTime()
      .toString(),
    pickUpLocation: ReservationDetails.pickUpLocationDetails.code,
    dropOffLocation: ReservationDetails.dropOffLocationDetails.code,
    reservationSource: ReservationDetails.reservationSource.code,
    macroGroupName: ReservationDetails.macroGroupName,
    discountValueWithoutVat: ReservationDetails.discountValueWithoutVat,
    agreementCoupon: ReservationDetails.agreementCoupon,
    showPics: "true",
    showOptionalImage: "true",
    showVehicleParameter: "true",
    showVehicleExtraImage: "true",
    showBookingDiscount: "true",
    isYoungDriverAge: ReservationDetails.isYoungDriverAge,
    isSeniorDriverAge: ReservationDetails.isSeniorDriverAge,
    noFeeAge: ReservationDetails.noFeeAge,
  }).toString();
  // console.log(searchParams);

  const options: Array<AppSecondaryNavigation> = [
    {
      to: "/embed",
      label: t(SecondaryNavConfig.Search),
    },
    {
      to: "/choose/vehicle?" + searchParams,
      label: t(SecondaryNavConfig.Choose),
    },
    {
      to: "#",
      label: t(SecondaryNavConfig.reserveAndPay),
      isActive: true,
    },
  ];
  const [loading, setLoading] = useState(false);
  const blurScreen = () => {
    document.body.style.opacity = String(0.5);
    document.body.style.pointerEvents = "none";
  };
  useEffect(() => {
    if (loading) {
      blurScreen();
    } else {
      document.body.style.opacity = String(1);
      document.body.style.pointerEvents = "";
    }
  }, [loading]);

  const handleUserType = (userType) => {
    setUserType(userType);
  };

  return (
    <section className="flex flex-col ">
      <div className="flex justify-center items-center"></div>

      {/*<div className="bg-white w-full box-border px-8">*/}
      {/*  <BreadCrumbs separator={<KeyboardArrowRight />} elements={options} />*/}
      {/*</div>*/}
      {/* <p className="text-black">{JSON.stringify(userDetails)}</p> */}
      <div className="min-h-screen">
        <div>
          <div className="grow flex flex-col grow gap-16 box-border p-8 bg-gray-100">
            <div className="bg-white box-border p-8">
              {" "}
              <ReservationDetailsPreview></ReservationDetailsPreview>
            </div>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              // setIsShowingPreview(true);
              setIsSaving(true);
              BookingApi.createBooking(
                ReservationDetails,
                UserDetails,
                VehicleDetails.vehicleDetails as Vehicle,
                VehicleDetails.paymentDetails as SelectedVehicleDetails,
                selectedPaymentMethod,
                companyinvoiceDetails,
                currentLoggedInuser,
                whetherIfGroupIsOnRequest,
                userType,
                checked
              ).then((e) => {
                if (e.toString() === "invalid.customer.ssn") {
                  setIsSaving(false);
                  setLoading(false);
                  if (inputTAXRef.current) {
                    inputTAXRef.current.focus();
                  }
                  alert(t("invalid ssn"));
                } else if (e.toString() === "invalid.companyTaxCode") {
                  setIsSaving(false);
                  setLoading(false);
                  alert("invalid company tax code");
                } else if (e.toString() === "invalid.company.vat") {
                  setIsSaving(false);
                  setLoading(false);
                  if (inputVATRef.current) {
                    inputVATRef.current.focus();
                  }
                  alert("invalid vat");
                } else {
                  setReservationId(e.data.data?.dbId);
                  setIsSaving(false);
                  setLoading(false);
                  switch (selectedPaymentMethod.toLowerCase()) {
                    case "paypal": {
                      payPalFormRef?.current?.submit();
                      break;
                    }
                    // case "nexi": {
                    //   // nexiFormRef?.current?.submit();
                    //   BookingApi.getPaymentLink(
                    //     e.data.data.dbId,
                    //     VehicleDetails.paymentDetails?.price || 0
                    //   ).then((e) => {
                    //     // console.log(e);
                    //     if (e.generateLink) {
                    //       window.open(e.generateLink, "_self");
                    //     }
                    //   });
                    //   break;
                    // }

                    case "nexi": {
                      if (
                        AppSettings?.onrequestPayAmount &&
                        VehicleDetails.vehicleDetails?.Status.toLowerCase() ==
                          "onrequest"
                      ) {
                        BookingApi.getPaymentLink(
                          e.data.data.dbId,
                          0.01,
                          "nexi"
                        ).then((e) => {
                          if (e.generateLink) {
                            window.open(e.generateLink, "_self");
                          }
                        });
                      } else {
                        BookingApi.getPaymentLink(
                          e.data.data.dbId,
                          VehicleDetails.paymentDetails?.price || 0,
                          "nexi"
                        ).then((e) => {
                          if (e.generateLink) {
                            window.open(e.generateLink, "_self");
                          }
                        });
                      }
                      break;
                    }
                    case "stripe": {
                      BookingApi.getPaymentLink(
                        e.data.data.dbId,
                        VehicleDetails.paymentDetails?.price || 0,
                        "Stripe"
                      ).then((e) => {
                        if (e.status == "Error") {
                          alert(e.errorMessage);
                        } else if (e.paymentUrl) {
                          const paymentData = {
                            ...e,
                            paymentMode: "Stripe",
                          };
                          window.open(paymentData.paymentUrl, "_self");
                        }
                      });
                      break;
                    }
                    case "wiretransfer": {
                      BookingApi.sendEmailForWireTransfer(
                        e.data.data?.dbId,
                        whetherIfGroupIsOnRequest
                      );
                      // console.log(e);
                      navigateTo("/booking/confirmed");
                      break;
                      // return BookingConfirmPage();
                    }
                    default: {
                      BookingApi.getPaymentLink(
                        e.data.data.dbId,
                        VehicleDetails.paymentDetails?.price || 0,
                        "nexi"
                      ).then((e) => {
                        // console.log(e);
                        if (e.generateLink) {
                          window.open(e.generateLink, "_self");
                        }
                      });
                      return;
                    }
                  }
                }
              });
            }}
            className="grow flex flex-col grow gap-16 box-border p-8 bg-gray-100"
          >
            <div className="bg-white box-border p-8">
              <h2 className="text-xl text-black pb-8 font-bold">
                {t("Personal Details")}
              </h2>
              <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                {Object.values(formFields).map((el) => {
                  if (el.key === "taxCode") {
                    return (
                      <AppInputSwitcher
                        {...el}
                        key={el.key}
                        label={translate(el.label || "")}
                        className={"h-20 sm:w-full box-border px-8"}
                        value={
                          userDetails[el.key] || formStates.getValues(el.key)
                        }
                        ref={inputTAXRef}
                        onchange={(e) => {
                          formStates.setValue(el.key, e.toString(), {
                            shouldValidate: true,
                          });
                        }}
                      />
                    );
                  } else {
                    return (
                      <AppInputSwitcher
                        {...el}
                        key={el.key}
                        label={translate(el.label || "")}
                        options={
                          el.key === "sex"
                            ? [translate("MALE"), translate("FEMALE")]
                            : el.options
                        }
                        className={"h-20 sm:w-full box-border px-8"}
                        value={
                          userDetails[el.key] || formStates.getValues(el.key)
                        }
                        onchange={(e) => {
                          formStates.setValue(el.key, e.toString(), {
                            shouldValidate: true,
                          });
                        }}
                      />
                    );
                  }
                })}
              </div>
            </div>
            <div className="flex flex-col bg-white box-border p-8   ">
              <div className="">
                <AppInputSwitcher
                  label={t("Contract Info")}
                  type="text"
                  readOnly={true}
                  variant="standard"
                  className={"h-22 mt-2 mb-2"}
                  onchange={() => {}}
                  key="info"
                  options={[]}
                />
              </div>
              <div className="flex flex-col">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={t("Invoice to company")}
                />
                <AppRadioButton
                  required={true}
                  value={userType}
                  onChange={(e) => {
                    handleUserType(e);
                  }}
                  options={[
                    {
                      label: t("Individual"),
                      value: "Individual",
                    },
                    {
                      label: t("Company"),
                      value: "Company",
                    },
                  ]}
                />
                {checked ? (
                  <form
                    onSubmit={handleSubmit((e) => {
                      onSubmit(e);
                    })}
                  >
                    <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                      {Object.values(individualFormFields).map((el, index) => {
                        let taxValue = "";
                        let readOnly;
                        el.readOnly ||
                          (ReadOnlyIfInitialValuesNotProvided.includes(
                            el.key
                          ) &&
                            !!initialValues[el.key]);
                        const options: RegisterOptions = {
                          required: {
                            value: !!el.required && !readOnly,
                            message: "This is a required field",
                          },
                        };
                        if (el.type === "email") {
                          options.pattern = {
                            value: /^[a-zA-Z\d+_.-]+@[a-zA-Z\d.-]+$/,
                            message: "Please provide a valid email",
                          };
                        }
                        if (el.key === "taxCode") {
                          taxValue = taxVal;
                          readOnly = isReadonly;
                        } else {
                          taxValue = "";
                        }
                        return (
                          <>
                            {userType === "Company" &&
                            (el.key === "birthNation" ||
                              el.key === "birthPlace" ||
                              el.key === "dateOfBirth" ||
                              el.key === "birthProvince" ||
                              el.key === "taxCode" ||
                              el.key === "firstName" ||
                              el.key === "lastName" ||
                              el.key === "sex") ? (
                              ""
                            ) : (
                              <div key={index}>
                                <AppInputSwitcher
                                  {...el}
                                  key={index}
                                  autocomplete={false}
                                  required={false}
                                  {...register(el.key, {
                                    ...options,
                                  })}
                                  readOnly={readOnly}
                                  value={
                                    getValues(el.key) ||
                                    initialValues[el.key] ||
                                    values[el.key] ||
                                    taxValue ||
                                    ""
                                  }
                                  options={
                                    el.key === "sex"
                                      ? [translate("MALE"), translate("FEMALE")]
                                      : el.options
                                  }
                                  label={translate(el.label || "")}
                                  placeholder={translate(el.placeholder || "")}
                                  className={"h-20 box-border px-8"}
                                  onchange={(e) => {
                                    const r: any = Object.keys(
                                      CompanyInvoiceFormFields
                                    ).at(index);
                                    if (
                                      el.key == "birthNation" &&
                                      e == "ITALIA"
                                    ) {
                                      setTaxVal("");
                                      setIsReadonly(false);
                                    } else if (
                                      el.key == "birthNation" &&
                                      e != "ITALIA"
                                    ) {
                                      setTaxVal("9999999999999999");
                                      setIsReadonly(true);
                                    }
                                    setCompanyinvoiceDetails({
                                      ...companyinvoiceDetails,
                                      [r]: e,
                                    });

                                    setValue(el.key, e || "", {
                                      shouldValidate: true,
                                    });
                                  }}
                                />
                                <FormFieldErrorMessage>
                                  {errors[el.key]?.message}
                                </FormFieldErrorMessage>
                              </div>
                            )}
                          </>
                        );
                      })}
                    </div>
                    {children}
                  </form>
                ) : (
                  ""
                )}
              </div>
              <div>
                <AppInputSwitcher
                  label={t("Info Privacy")}
                  type="text"
                  variant="standard"
                  className={"h-22 mt-2 mb-2"}
                  onchange={() => {}}
                  key="privacy"
                  options={[]}
                  readOnly={true}
                />
              </div>
              <div className="flex flex-row flex-wrap gap-8">
                <AppStandardButton
                  // clickHandler={() => {
                  //   const link = document.createElement("a");
                  //   link.download = `privacy policy.pdf`;
                  //   link.href = "/assets/t&c/PrivacyPolicy_en.pdf";
                  //   link.click();
                  // }}
                  onClick={() => {
                    BookingApi.getPrivacyAndTerms().then((e) => {
                      const url = e.data.termsConditionFile;
                      fetch(url).then(async (e) => {
                        const fileBlob = await e.blob();
                        const linkObj = document.createElement("a");
                        linkObj.href = URL.createObjectURL(fileBlob);
                        linkObj.target = "_blank";
                        linkObj.click();
                        return fileBlob;
                      });
                    });
                  }}
                  variant="contained"
                  color="addInsuranceButton"
                  style={{ borderRadius: "0" }}
                  className="h-12 w-80 mt-8"
                >
                  {t("Download terms and conditions")}{" "}
                </AppStandardButton>
                <AppStandardButton
                  onClick={() => {
                    BookingApi.getPrivacyAndTerms().then((e) => {
                      const url = e.data.privacyfileUrl;

                      fetch(url).then(async (e) => {
                        const fileBlob = await e.blob();
                        // let file=new File([fileBlob],"test.pdf",{
                        //type: e.type
                        // });

                        const linkObj = document.createElement("a");
                        linkObj.href = URL.createObjectURL(fileBlob);
                        linkObj.target = "_blank";
                        linkObj.click();
                        return fileBlob;
                      });

                      // const link = document.createElement("a");
                      // link.href = e.data.termsConditionFile;
                      // // link.download = e.data.termsConditionFile;
                      // link.target = "_blank";
                      // link.click();
                    });
                  }}
                  variant="contained"
                  color="addInsuranceButton"
                  style={{ borderRadius: "0" }}
                  className="h-12 w-80 mt-8"
                >
                  {t("Download the privacy policy")}{" "}
                </AppStandardButton>
              </div>
              <AppStandardMultiSelectCheckBox
                required={true}
                header={t(
                  "By clicking you're agreeing to our terms and conditions"
                )}
                options={[
                  {
                    label: t("I have read and agree to the rental conditions"),
                    value: "I have read and agree to the rental conditions",
                  },
                ]}
              />
            </div>
            <footer className="flex flex-row flex-wrap bg-white box-border p-8">
              <div className="flex flex-col grow">
                <AppRadioButton
                  required={true}
                  label={t("Mode of Payment")}
                  value={selectedPaymentMethod}
                  options={Object.keys(allPaymentMethods)
                    .map((el) => el.toUpperCase())
                    .map((el) => {
                      switch (el) {
                        case "WIRETRANSFER":
                          return {
                            label: t("Pay at desk"),
                            value: "WIRETRANSFER",
                          };
                        case "PAYPAL":
                          return {
                            label: t("PayPal"),
                            value: "PAYPAL",
                          };
                        case "NEXI":
                          return {
                            label: t("Credit card"),
                            value: "NEXI",
                          };
                        case "STRIPE":
                          return {
                            label: t("Stripe"),
                            value: "STRIPE",
                          };
                        default:
                          return {
                            label: "N/A",
                          };
                      }
                    })}
                  onChange={(e) => {
                    setSelectedPaymentMethod(e);
                  }}
                />
              </div>
              <div className="flex flex-row gap-6">
                <AppButton
                  loading={isSaving}
                  type="submit"
                  color="bookingButtonColor"
                  className="h-12 w-44 flex justify-center rounded-sm"
                  onClick={() => {}}
                >
                  <span className="flex text-center w-full h-full mt-3 justify-center">
                    {t("Confirm")}
                  </span>
                </AppButton>
              </div>
              <div className="flex flex-row  text-[#707070] mt-4 mb-2">
                {AppSettings?.onrequestPayAmount &&
                VehicleDetails.vehicleDetails?.Status.toLowerCase() ==
                  "onrequest"
                  ? t(
                      "The reservation will be subjected to confirmation – during the payment process, you will be asked to pay €0.01 and, once the reservation is confirmed, you will be charged the total amount of the rental on the registered credit card."
                    )
                  : ""}
              </div>
            </footer>
          </form>
        </div>
      </div>
      <div className={"text-black hidden"}>
        {allPaymentMethods?.paypal && allPaymentMethods?.paypal?.length > 0 && (
          <PayPal
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={payPalFormRef}
            postUrl={allPaymentMethods.paypal[0]?.sandboxPaymentURL || ""}
            companyEmail={
              allPaymentMethods.paypal[0]?.sandboxEmailCompany || ""
            }
            amount={VehicleDetails.paymentDetails?.price || 0}
            returnLink={allPaymentMethods.paypal[0]?.sandboxReturnURL || ""}
            notify_url={allPaymentMethods.paypal[0]?.sandboxNotifyURL || ""}
            cancel_url={allPaymentMethods.paypal[0]?.sandboxCancelURL || ""}
            itemName={"NAME HERE"}
            reservationId={ReservationId}
          />
        )}
        {/* {allPaymentMethods && "nexi" in allPaymentMethods && (
          <Nexi
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={nexiFormRef}
            postUrl={allPaymentMethods.nexi[0]?.customAttribute1 || ""}
            // companyEmail={allPaymentMethods.nexi[0]?.fieldName || ""}
            amount={paymentDetails?.price || 0}
            returnLink={"https://www.google.com"}
            notify_url={"https://www.youtube.com"}
            cancel_url={"https://www.test.com"}
            itemName={"NAME HERE"}
          />
        )} */}
      </div>
    </section>
  );
}
export { PaymentDetailsPage };
export const isBookingForm = true;
