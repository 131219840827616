import { useEffect, useState } from "react";

import { ChosenOptions } from "../BookingVehicleInfoCard/BookingVehicleInfoCard.props";
import React from "react";
import { SelectVehicleExtrasProps } from "./SelectVehicleExtras.props";
import { SelectedVehicleExtraOptions } from "../../../@types/VehicleOptions";
import VehicleExtraOptions from "../../UI/VehicleExtraOptions";

SelectVehicleExtras.defaultProps = {
  onChange: () => {},
};
function SelectVehicleExtras({
  options,
  onChange,
  values = {},
  showExtras,
}: SelectVehicleExtrasProps): JSX.Element {
  const [selectedOptions, setSelectedOptions] =
    useState<ChosenOptions["options"]>(values);
  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions]);
  return (
    <div className="flex md:flex-row md:flex-wrap flex-col h-full gap-2">
      {options.map((el, index) => (
        <div
          key={index}
          className="md:w-56 w-full aspect-square max-h-[7.5rem] border-solid border-2 border-gray-300 box-border p-2 mx-4 flex"
        >
          <VehicleExtraOptions
            onDecrementClick={() => {
              setSelectedOptions((prev) => {
                const optionName = el.optionName;
                if (!prev[optionName]) {
                  return { ...prev };
                }
                let quantity = prev[optionName].quantity;
                quantity--;
                prev[optionName].quantity = Math.max(quantity, 0);
                prev[optionName].name = el.optionName;
                prev[optionName].price = el.pricePerDayInUSD;
                if (!prev[optionName].quantity) {
                  delete prev[optionName];
                }
                return { ...prev };
              });
            }}
            onIncrementClick={() => {
              setSelectedOptions((prev) => {
                let maxAllowed: number = 1;
                const optionName = el.optionName;
                if (el.maxAllowed) {
                  maxAllowed = el.maxAllowed;
                }
                if (!(prev[optionName] && prev[optionName].quantity)) {
                  prev[optionName] = {};
                  prev[optionName].name = optionName;
                  prev[optionName].quantity = 0;
                  prev[optionName].price = options[index].pricePerDayInUSD;
                }

                let quantity = prev[optionName].quantity;
                quantity++;
                prev[optionName].quantity = Math.min(quantity, maxAllowed);
                return { ...prev };
              });
            }}
            {...el}
            value={
              selectedOptions[el.optionName]
                ? selectedOptions[el.optionName].quantity || 0
                : 0
            }
            showExtras={showExtras}
          />
        </div>
      ))}
    </div>
  );
}
export { SelectVehicleExtras as default };
export { SelectVehicleExtras };
