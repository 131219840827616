import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system";

export const useLoggedInUserMenuStyles = makeStyles((theme: Theme) => {
  return {
    root() {
      return {
        "& .MuiPaper-root": {
          background: theme.palette.blue.main,
          color: "white",
          borderTopRightRadius: "1.2rem",
          borderBottomLeftRadius: "1.2rem",
        },
        "& .MuiDivider-root": {
          background: "white",
        },
        "& .MuiListItemIcon-root": {
          color: "white",
        },
      };
    },
  };
});
