import {
  Facebook,
  Twitter,
  Instagram,
  Mail,
  Pinterest,
  LinkedIn,
} from "@mui/icons-material";
import { SocialMediaLinks } from "../../../@types/SocialMediaLinks";
import AppButton from "../AppButtons/AppButton";
import React from "react";

const SocialMediaIcons = {
  twitter: <Twitter className="text-blue-400" />,
  facebook: <Facebook className="text-blue-900" />,
  instagram: <Instagram className="" />,
  email: <Mail className="text-gray-600" />,
  pinterest: <Pinterest className="text-rose-900" />,
  linkedIn: <LinkedIn className="text-blue-900" />,
};

export default function AppSocialMediaLinks(socialLinks: SocialMediaLinks) {
  const SocialMediaLinksToRender: Array<JSX.Element> = [];
  for (const i in socialLinks) {
    if (SocialMediaIcons[i]) {
      SocialMediaLinksToRender.push(
        <AppButton
          className="grow shrink"
          variant="clear"
          href={socialLinks[i]}
          endIcon={SocialMediaIcons[i]}
          key={i}
        />
      );
    }
  }

  return (
    <div className="flex flex-row flex-wrap">{SocialMediaLinksToRender}</div>
  );
}
