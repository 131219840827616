import {
  Divider,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
} from "@mui/material";
import { Link } from "react-router-dom";
import { LoggedInUserMenuProps } from "./AppMenu.props";
import { useLoggedInUserMenuStyles } from "./AppMenu.styles";
// LoggedInUserMenu.propTypes = { ...LoggedInUserMenuProps };
import React from "react";

LoggedInUserMenu.defaultProps = {
  isOpen: false,
  clickHandler: () => {},
  changeOptionSelected: () => {},
};
export function LoggedInUserMenu({
  anchorElement,
  options,
  isOpen = false,
  handleClose = () => {},
  menuHeader = {
    labelClickHandler: () => {},
  },
  selectedIndex,
  changeOptionSelected = () => {},
}: LoggedInUserMenuProps) {
  const classStyles = useLoggedInUserMenuStyles();
  return (
    <Menu
      className={classStyles.root}
      anchorEl={anchorElement}
      // onClick={clickHandler}
      open={isOpen}
      onClose={handleClose}
    >
      {menuHeader && (
        <div>
          <ListItemButton className="user-details flex flex-row">
            <ListItemText
              onClick={() => {
                if (menuHeader.labelClickHandler instanceof Function) {
                  menuHeader.labelClickHandler();
                }
              }}
            >
              {menuHeader.label}
            </ListItemText>
            <ListItemAvatar
              onClick={() => {
                if (menuHeader.avatarClickHandler instanceof Function) {
                  menuHeader.avatarClickHandler();
                }
              }}
            >
              {menuHeader.avatar}
            </ListItemAvatar>
          </ListItemButton>
          <Divider />
        </div>
      )}
      {options.map((el, index) => {
        return (
          <div key={index}>
            <ListItemButton
              component={Link}
              to={el.href || "#"}
              selected={selectedIndex == index}
              onClick={() => {
                changeOptionSelected(index);
                // el.clickHandler();
              }}
            >
              {el.iconUrl && <ListItemIcon> {el.iconUrl}</ListItemIcon>}
              {el.label && <ListItemText> {el.label}</ListItemText>}
            </ListItemButton>
            {index < options.length - 1 && <Divider />}
          </div>
        );
      })}
    </Menu>
  );
}
