import AppStoreLinksProps from "./AppStoreLink.props";
import React from "react";

AppStoreLinks.propTypes = { ...AppStoreLinksProps };
export default function AppStoreLinks({ links, className = "" }) {
  return (
    <main className={"flex flex-row flex-wrap gap-2 " + className}>
      <div>
        <IosStoreLink link={links.ios}></IosStoreLink>
      </div>
      <div>
        <AndroidStoreLink link={links.android} />
      </div>
    </main>
  );
}

/**
 *
 * helper components
 *
 */
import GoogleIcon from "@mui/icons-material/Google";
import AppButton from "../AppButtons/AppButton";

IosStoreLink.propTypes = {};
function IosStoreLink({ link }) {
  return (
    <div className="w-full box-border px-4 flex flex-row items-center border-solid border-2 border-gray-600 bg-white rounded-full">
      <main>
        <GoogleIcon />
      </main>
      <AppButton variant="clear" href={link} className="grow h-fit">
        <div className="flex flex-col text-gray-600">
          <span className="text-2xs">Download on the</span>
          <h2 className="text-medium font-bold">Apple Store</h2>
        </div>
      </AppButton>
    </div>
  );
}
function AndroidStoreLink({ link }) {
  return (
    <div className="w-full box-border px-4 flex flex-row items-center border-solid border-2 border-gray-600 bg-white rounded-full">
      <main>
        <GoogleIcon />
      </main>
      <AppButton variant="clear" href={link} className="grow h-fit">
        <div className="flex flex-col text-gray-600">
          <span className="text-2xs">Get it on</span>
          <h2 className="text-medium font-bold">Google play</h2>
        </div>
      </AppButton>
    </div>
  );
}
