import React, { useEffect, useState } from "react";
import { AppStandardMultiSelectCheckBox } from "../../components/UI/AppMultipleSelection/";
import { BOOKING_ACTIONS } from "../../store/onlineBooking";
import BookingApi from "../../API/Apis/BookingApi";
import { BookingDetails } from "../../@types/Booking/bookingDetails";
import { BookingGetters } from "../../store/onlineBooking/getters";
import { BreadCrumbs } from "../../components/UI/AppBreadCrumbs";
import { FilterList, KeyboardArrowRight } from "@mui/icons-material";
import SecondaryNavConfig from "../../API/responses/SecondaryNavConfig.json";
import { StateSelectedVehicleDetails } from "../../@types/states/StateSelectedVehicleDetails";
import { TimeInMinutes } from "../../helpers/timerConfig";
import { Vehicle } from "../../@types/DTO/Vehicles.d";
import VehicleFilterOptions from "../../API/responses/VehicleFilterOptions.json";
import { addMinutes } from "date-fns";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ShowVehicles } from "../../components/Layouts/ShowVehicles/ShowVehicles";
import { ShowVehiclesProps } from "../../components/Layouts/ShowVehicles/ShowVehiclesProps";
import { CircularProgress } from "@mui/material";
import AppButton from "../../components/UI/AppButtons";
import AppModal from "../../components/UI/AppModal";
import { t } from "i18next";
// import googleTagManager from "../../config/googleTagManager";
// import TagManager from "react-gtm-module";
import { useSearchParams } from "react-router-dom";
import { BookingUrlParams } from "../../@types/Booking/linkRedirection";
import { useTranslation } from "react-i18next";
import "./Scroller.css";
// import { useSearchParams } from "react-router-dom";
// import { GTM } from "../../config/googleTagManager";
// GTM;
function ChooseVehiclePage() {
  const { t: translate } = useTranslation();
  const options = [
    {
      to: "/embed",
      label: translate(SecondaryNavConfig.Search),
    },
    {
      to: "#",
      label: translate(SecondaryNavConfig.Choose),
      isDisabled: true,
      isActive: false,
    },
    {
      to: "#",
      label: translate(SecondaryNavConfig.reserveAndPay),
      isDisabled: true,
    },
  ];
  const dispatch = useDispatch();
  const [totalElements] = useState<number>(3);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const AppSettings = BookingGetters.getAppSettings();
  // const tagManagerArgs = {
  //   gtmId: AppSettings?.tagManagerId,
  // };
  // TagManager.initialize(tagManagerArgs);
  const loadingScreenArrays = Object.keys(new Array(totalElements));
  const [vehicleAvailabilityFilter, setVehicleAvailabilityFilter] = useState<
    ShowVehiclesProps["vehiclesFilter"]
  >({
    status: {},
    category: {},
    type: {},
    TotalSeats: [],
    MacroGroup: {},
    Transmission: {},
  });
  const [tempAvailabilityFilter, setTempAvailabilityFilter] = useState<
    ShowVehiclesProps["vehiclesFilter"]
  >({
    status: {
      available: "available",
      // unavailable: "Unavailable",
      // onrequest: "OnRequest",
    },
    category: {},
    type: {
      // diesel: "diesel",
      // benzina: "benzina",
      // ibrida: "ibrida",
      // elettrico: "elettrico",
    },
    TotalSeats: [],
    MacroGroup: {},
    Transmission: {},
  });
  const history = useNavigate();
  const [allVehicles, setAllVehicles] = useState<Vehicle[]>([]);
  const [selectedVehicle, setSelectedVehicle] =
    useState<StateSelectedVehicleDetails>({
      vehicleDetails: undefined,
      paymentDetails: undefined,
    });
  const bookingInformation: BookingDetails = BookingGetters.getBookingDetails();
  const reduxBookingDetails: BookingDetails =
    BookingGetters.getBookingDetails();

  const [searchParams, setSearchParams] = useSearchParams();
  const [discountValueWithoutVat, setDiscountValueWithoutVat] =
    useState<string>(
      reduxBookingDetails.discountValueWithoutVat
        ? reduxBookingDetails.discountValueWithoutVat
        : "0"
    );
  const navigation = useNavigate();
  const [showNavigationModal, setShowNavigationModal] =
    useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    const t: BookingUrlParams = {
      dropOffDateAndTime: searchParams.get("dropOffDateAndTime") || "",
      pickUpDateAndTime: searchParams.get("pickUpDateAndTime") || "",
      pickUpLocation: searchParams.get("pickUpLocation") || "",
      dropOffLocation: searchParams.get("dropOffLocation") || "",
      reservationSource: searchParams.get("reservationSource") || "WEB001",
      macroGroupName: searchParams.get("macroGroupName") || " ",
      discountValueWithoutVat:
        searchParams.get("discountValueWithoutVat") || " ",
      showPics: searchParams.get("showPics") || "true",
      showOptionalImage: searchParams.get("showOptionalImage") || "true",
      showVehicleParameter: searchParams.get("showVehicleParameter") || "true",
      showBookingDiscount: searchParams.get("showBookingDiscount") || "true",
      agreementCoupon: searchParams.get("agreementCoupon") || " ",
      showVehicleExtraImage:
        searchParams.get("showVehicleExtraImage") || "true",
      isYoungDriverAge: searchParams.get("isYoungDriverAge") || "",
      isSeniorDriverAge: searchParams.get("isSeniorDriverAge") || "",
      noFeeAge: searchParams.get("noFeeAge") || "",
      isAgeLimitSelected: searchParams.get("isAgeLimitSelected") || "",
    };
    for (const i in t) {
      if (!t[i]) {
        // console.log("redirecting", i);

        navigation("/");
      }
    }
    const currentBookingDetails: BookingDetails = {
      pickUpDateAndTime: new Date(parseInt(t.pickUpDateAndTime)).getTime(),
      dropOffDateAndTime: new Date(parseInt(t.dropOffDateAndTime)).getTime(),
      dropOffLocationDetails: { name: "", code: t.dropOffLocation },
      pickUpLocationDetails: { name: "", code: t.pickUpLocation },
      reservationSource: { name: "", code: t.reservationSource || "" },
      macroGroupName: t.macroGroupName || "",
      showPics: t.showPics == "true",
      showOptionalImage: t.showOptionalImage == "true",
      showVehicleParameter: t.showVehicleParameter == "true",
      showVehicleExtraImage: t.showVehicleExtraImage == "true",
      showBookingDiscount: t.showBookingDiscount == "true",
      agreementCoupon: t.agreementCoupon || "",
      discountValueWithoutVat: t.discountValueWithoutVat || "",
      isYoungDriverAge: t.isYoungDriverAge || "",
      isSeniorDriverAge: t.isSeniorDriverAge || "",
      noFeeAge: t.noFeeAge || "",
      isAgeLimitSelected: t.isAgeLimitSelected || "",
    };

    BookingApi.getReservationLocations()
      .then((locationResponse) => {
        const dropOffLocation = locationResponse.data.find(
          (location) => location.locationCode == t.dropOffLocation
        );
        const pickUpLocation = locationResponse.data.find(
          (location) => location.locationCode == t.pickUpLocation
        );
        if (!pickUpLocation || !dropOffLocation) {
          navigation("/");
          return Promise.reject(new Error("Invalid location"));
        }
        currentBookingDetails.pickUpLocationDetails = {
          name: pickUpLocation?.locationName || dropOffLocation?.locationName,
          code: pickUpLocation?.locationCode || dropOffLocation?.locationCode,
        };
        currentBookingDetails.dropOffLocationDetails = {
          name: dropOffLocation?.locationName,
          code: dropOffLocation?.locationCode,
        };

        return BookingApi.getAllReservationSources();
      })
      .then((reservationSourcesResponse) => {
        const reservationSource = reservationSourcesResponse.find((source) => {
          return source.Codice == t.reservationSource;
        });
        if (!reservationSource) {
          navigation("/");
          return Promise.reject(new Error("Invalid reservation source"));
        }

        currentBookingDetails.reservationSource = {
          name: reservationSource["Ragione Sociale"],
          code: reservationSource.Codice,
        };

        dispatch(
          BOOKING_ACTIONS.setBookingInformation({
            ...currentBookingDetails,
          })
        );

        return BookingApi.getReservationVehicles(currentBookingDetails);
      })
      .then((vehiclesResponse) => {
        dispatch(
          BOOKING_ACTIONS.setSessionExpiryTime(
            addMinutes(new Date(), TimeInMinutes).getTime()
          )
        );
        setAllVehicles([...(vehiclesResponse.data?.data?.Vehicles || [])]);
      })
      .catch((error) => {
        console.error(error);
        navigation("/");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (selectedVehicle.paymentDetails) {
        dispatch(BOOKING_ACTIONS.setSelectedVehicleDetails(selectedVehicle));
        history("/booking/pay");
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [selectedVehicle]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setVehicleAvailabilityFilter(tempAvailabilityFilter);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [tempAvailabilityFilter]);

  {
    AppSettings?.enableFilterAvailUnAvail && <VehicleFilters></VehicleFilters>;
  }
  function VehicleFilters() {
    return (
      <>
        <h2 className="font-bold text-xl text-gray-600 pb-8 box-border">
          {t("Filter to Vehicles")}
        </h2>
        <div
          className={
            "h-full overflow-hidden hover:overflow-auto flex flex-col box-border px-4"
          }
        >
          {/* <AppStandardMultiSelectCheckBox
            value={Object.values(tempAvailabilityFilter?.status || {}).map(
              (e) => e.toLowerCase()
            )}
            onChange={async (e) => {
              const filterMap = e.reduce((prev, current) => {
                prev[current.replace(/-/g, "").toLowerCase()] = current;
                return prev;
              }, {});
              setTempAvailabilityFilter({
                type: tempAvailabilityFilter?.type || {},
                category: tempAvailabilityFilter?.category || {},
                status: filterMap,
                TotalSeats: tempAvailabilityFilter?.TotalSeats || 2,
                MacroGroup: tempAvailabilityFilter?.MacroGroup || {},
              });
            }}
            header="Availability"
            options={[
              {
                label: `Available (${
                  allVehicles.filter(
                    (e) => e.Status.toLowerCase() === "available"
                  ).length
                })`,
                value: "available",
              },
              {
                label: `Unavailable  (${
                  allVehicles.filter(
                    (e) => e.Status.toLowerCase() === "unavailable"
                  ).length
                })`,
                value: "unavailable",
              },
              {
                label: `On-Request  (${
                  allVehicles.filter(
                    (e) => e.Status.toLowerCase() === "onrequest"
                  ).length
                })`,
                value: "onrequest",
              },
            ]}
          /> */}
          <AppStandardMultiSelectCheckBox
            value={Object.values(tempAvailabilityFilter?.type || {})}
            onChange={async (e) => {
              const type1 = (e || []).reduce((prev, current) => {
                prev[current.toLowerCase()] = current;
                return prev;
              }, {});
              setTempAvailabilityFilter({
                type: type1,
                category: tempAvailabilityFilter?.category || {},
                status: tempAvailabilityFilter?.status || {},
                TotalSeats: tempAvailabilityFilter?.TotalSeats || [],
                MacroGroup: tempAvailabilityFilter?.MacroGroup || {},
                Transmission: tempAvailabilityFilter?.Transmission || {},
              });
            }}
            header={t("Type of Fuel")}
            options={[
              {
                label: translate("Diesel"),
                value: "DIESEL".toLowerCase(),
              },
              {
                label: translate("Electric"),
                value: "ELETTRICO".toLowerCase(),
              },
              {
                label: translate("Petrol"),
                value: "BENZINA".toLowerCase(),
              },
              {
                label: translate("Hybrid"),
                value: "IBRIDA".toLowerCase(),
              },
            ]}
          />
          <AppStandardMultiSelectCheckBox
            value={Object.values(tempAvailabilityFilter?.TotalSeats || {})}
            onChange={async (e) => {
              setTempAvailabilityFilter({
                type: tempAvailabilityFilter?.type || {},
                category: tempAvailabilityFilter?.category || {},
                status: tempAvailabilityFilter?.status || {},
                TotalSeats: e || [],
                MacroGroup: tempAvailabilityFilter?.MacroGroup || {},
                Transmission: tempAvailabilityFilter?.Transmission || {},
              });
            }}
            header={translate("No. of Seats")}
            options={[
              {
                label: translate("2 Seats"),
                value: 2,
              },
              {
                label: translate("5 Seats"),
                value: 5,
              },
              {
                label: translate("7 Seats"),
                value: 7,
              },
              {
                label: translate("9 Seats"),
                value: 9,
              },
            ]}
          />
          <AppStandardMultiSelectCheckBox
            value={Object.values(tempAvailabilityFilter?.Transmission || {})}
            onChange={async (e) => {
              setTempAvailabilityFilter({
                type: tempAvailabilityFilter?.type || {},
                category: tempAvailabilityFilter?.category || {},
                status: tempAvailabilityFilter?.status || {},
                TotalSeats: tempAvailabilityFilter?.TotalSeats || [],
                MacroGroup: tempAvailabilityFilter?.MacroGroup || {},
                Transmission: (e || []).reduce((prev, current) => {
                  prev[current.toLowerCase()] = current;
                  return prev;
                }, {}),
              });
            }}
            header={translate("Transmission")}
            options={[
              {
                label: translate("Manual"),
                value: "MANUALE".toLowerCase(),
              },
              {
                label: translate("Automatic"),
                value: "AUTOMATICO".toLowerCase(),
              },
            ]}
          />

          {/* {VehicleFilterOptions.map((el, index) => (
            <AppStandardMultiSelectCheckBox
              onChange={(e) => {}}
              key={index}
              header={el.header}
              options={el.options.map((el) => el)}
              required={false}
            />
          ))}  Removing Filters from json */}
        </div>
      </>
    );
  }
  return (
    <div className="flex flex-col box-border sm:px-6 gap-4">
      <div className={"md:hidden block"}>
        <AppButton
          onClick={() => {
            setShowNavigationModal(true);
          }}
          className={"w-fit"}
          variant={"outlined"}
          color={"addInsuranceButton"}
        >
          <FilterList />
        </AppButton>
        <AppModal
          handleClose={() => {
            setShowNavigationModal(false);
          }}
          isOpen={showNavigationModal}
        >
          <VehicleFilters />
        </AppModal>
      </div>
      <div className="flex flex-row gap-4 ">
        <main className="flex flex-col gap-4 grow box-border w-full">
          {!isLoading ? (
            <>
              <ShowVehicles
                onVehicleSelect={(e: any) => {
                  setSelectedVehicle(e);
                  setDiscountValueWithoutVat(e.vehicleDetails.Reference.ID);
                }}
                allVehicles={allVehicles}
                vehiclesFilter={vehicleAvailabilityFilter}
              />
            </>
          ) : (
            <div className={"flex flex-col justify-center items-center w-full"}>
              <CircularProgress />
            </div>
          )}
        </main>
        <aside
          className="w-80 h-screen sticky top-0 lg:flex hidden flex-col cursor-pointer"
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            border: "1px solid lightgray",
          }}
        >
          <VehicleFilters />
        </aside>
      </div>
    </div>
  );
}

export { ChooseVehiclePage };
